import React from "react";
import socialLinks from "../constants/social_links";
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <div className="footer-links social-links">
          {socialLinks.map(link => {
            return (
              <a
                href={link.url}
                key={link.id}
                className="social-link"
                target="_blank"
              >
                {link.icon}
              </a>
            );
          })}
        </div>
        <h5>
          copyright&copy;{new Date().getFullYear()}
          <span>Giorgio Federici</span> all rights reserved
        </h5>
      </div>
    </footer>
  );
};

export default Footer;
